var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "b-row",
        { staticClass: "mb-4" },
        [_c("b-col", [_c("h1", [_vm._v("Albums")])])],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { cols: "4" } },
            [
              _c(
                "label",
                { staticClass: "mr-4", attrs: { for: "text-search" } },
                [_vm._v("Search")]
              ),
              _c("b-form-input", {
                attrs: { id: "text-search" },
                on: {
                  keydown: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.setSearch($event)
                  }
                },
                model: {
                  value: _vm.filter.q,
                  callback: function($$v) {
                    _vm.$set(_vm.filter, "q", $$v)
                  },
                  expression: "filter.q"
                }
              }),
              _c("p", { staticClass: "mt-2 mb-0" }, [
                _vm._v(
                  "You can search for Artist, Title, ReportingCode and Barcode."
                )
              ])
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: "4" } },
            [
              _c("label", { attrs: { for: "release-date" } }, [
                _vm._v("Release Date")
              ]),
              _c("b-form-datepicker", {
                attrs: { "reset-button": true, id: "release-date" },
                model: {
                  value: _vm.filter.ReleaseDate,
                  callback: function($$v) {
                    _vm.$set(_vm.filter, "ReleaseDate", $$v)
                  },
                  expression: "filter.ReleaseDate"
                }
              })
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: "4" } },
            [
              _c("label", { attrs: { for: "status-select" } }, [
                _vm._v("Status")
              ]),
              _c("c-select", {
                attrs: {
                  value: "filter.DeliveryStatusID",
                  id: "status-select",
                  placeholder: "---- All ----",
                  options: _vm.statuses
                },
                on: {
                  input: function($event) {
                    return _vm.$set(
                      _vm.filter,
                      "DeliveryStatusID",
                      $event.target.value
                    )
                  }
                }
              })
            ],
            1
          ),
          _c(
            "b-col",
            { staticStyle: { "padding-top": "1.75em" }, attrs: { cols: "3" } },
            [
              _c(
                "b-button",
                {
                  staticClass: "mr-2",
                  staticStyle: { width: "45%" },
                  attrs: { variant: "primary" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.setSearch($event)
                    }
                  }
                },
                [_vm._v("Search")]
              ),
              _c(
                "b-button",
                {
                  staticStyle: { width: "45%" },
                  attrs: { variant: "light" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.resetSearch($event)
                    }
                  }
                },
                [_vm._v("Reset")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mt-3" },
        [
          _c(
            "b-col",
            [
              _c("rest-table", {
                attrs: {
                  endpoint: "albums",
                  filter: _vm.activeFilter,
                  fields: _vm.fields
                },
                scopedSlots: _vm._u([
                  {
                    key: "cell(artistTitle)",
                    fn: function(data) {
                      return [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                name: "release-detail",
                                params: { id: data.item.AlbumID }
                              }
                            }
                          },
                          [
                            _c("strong", [
                              _vm._v(
                                _vm._s(data.item.ProductDetails.Attribution)
                              )
                            ]),
                            _vm._v(
                              " - " +
                                _vm._s(data.item.ProductDetails.Title) +
                                " "
                            ),
                            data.item.ProductDetails.VersionTitle
                              ? _c("span", [
                                  _vm._v(
                                    " | " +
                                      _vm._s(
                                        data.item.ProductDetails.VersionTitle
                                      )
                                  )
                                ])
                              : _vm._e()
                          ]
                        )
                      ]
                    }
                  },
                  {
                    key: "cell(ReleaseDate)",
                    fn: function(data) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("dateFormat")(data.item.ReleaseDate)
                            ) +
                            " "
                        )
                      ]
                    }
                  },
                  {
                    key: "cell(ProductDetails.DeliveryStatus.Description)",
                    fn: function(data) {
                      return [
                        _c("div", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.deliveryStatusFormatter(data.item)
                            )
                          }
                        })
                      ]
                    }
                  },
                  {
                    key: "cell(CoverArtAvailable)",
                    fn: function(data) {
                      return [
                        data.item.CoverArtAvailable == "1"
                          ? _c(
                              "span",
                              {
                                staticStyle: {
                                  "font-weight": "bold",
                                  color: "green"
                                }
                              },
                              [_vm._v("V")]
                            )
                          : _vm._e(),
                        data.item.CoverArtAvailable == "0"
                          ? _c(
                              "span",
                              {
                                staticStyle: {
                                  "font-weight": "bold",
                                  color: "red"
                                }
                              },
                              [_vm._v("X")]
                            )
                          : _vm._e()
                      ]
                    }
                  },
                  {
                    key: "cell(actions)",
                    fn: function(data) {
                      return [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                name: "release-detail",
                                params: { id: data.item.AlbumID }
                              }
                            }
                          },
                          [_c("b-icon-pencil-square")],
                          1
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }