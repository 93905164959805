<template>
  <b-container fluid>
    <b-row class="mb-4">
      <b-col>
        <h1>Albums</h1>
      </b-col>

    </b-row>

    <b-row>
      <b-col cols="4">
        <label for="text-search" class="mr-4">Search</label>
        <b-form-input id="text-search" class="" v-model="filter.q" @keydown.enter="setSearch"></b-form-input>
        <p class="mt-2 mb-0">You can search for Artist, Title, ReportingCode and Barcode.</p>
      </b-col>

      <b-col cols="4">
        <label for="release-date">Release Date</label>
        <b-form-datepicker 
            :reset-button="true"
            v-model="filter.ReleaseDate"
            id="release-date">

        </b-form-datepicker>
      </b-col>

      <b-col cols="4">
        <label for="status-select">Status</label>
        <c-select
          value="filter.DeliveryStatusID"
          @input="$set(filter, 'DeliveryStatusID', $event.target.value)"
          id="status-select"
          placeholder="---- All ----"
          :options="statuses" />
      </b-col>

      <b-col cols="3" style="padding-top: 1.75em;">
        <b-button variant="primary" class="mr-2" style="width: 45%;" v-on:click.prevent="setSearch">Search</b-button>
        <b-button variant="light" style="width: 45%;" v-on:click.prevent="resetSearch">Reset</b-button>
      </b-col>
    </b-row>

    <b-row class="mt-3">
      <b-col>
        <rest-table
            endpoint="albums"
            :filter="activeFilter"
            :fields="fields">

          <template v-slot:cell(artistTitle)="data">
            <router-link :to="{ name: 'release-detail', params: { id: data.item.AlbumID } }">
              <strong>{{ data.item.ProductDetails.Attribution }}</strong> - {{ data.item.ProductDetails.Title }}
              <span v-if="data.item.ProductDetails.VersionTitle"> | {{ data.item.ProductDetails.VersionTitle }}</span>
            </router-link>
          </template>

          <template v-slot:cell(ReleaseDate)="data">
            {{ data.item.ReleaseDate|dateFormat }}
          </template>

          <template v-slot:cell(ProductDetails.DeliveryStatus.Description)="data">
            <div v-html="deliveryStatusFormatter(data.item)"></div>
          </template>

          <template v-slot:cell(CoverArtAvailable)="data">
            <span v-if="data.item.CoverArtAvailable == '1'" style="font-weight: bold; color: green">V</span>
            <span v-if="data.item.CoverArtAvailable == '0'" style="font-weight: bold; color: red">X</span>
          </template>

          <template v-slot:cell(actions)="data">
            <router-link :to="{ name: 'release-detail', params: { id: data.item.AlbumID } }">
              <b-icon-pencil-square></b-icon-pencil-square>
            </router-link>
          </template>
        </rest-table>
      </b-col>
    </b-row>  
  </b-container>
</template>

<script>
  import RestTable from '@/components/RestTable.vue'

  export default {
    name: 'Releases',
    components: {
      RestTable,
    },
    data () {
      return {
      
        labelOptions: [],
        filter: {
          q: '',
          LabelID: '',
          DeliveryStatusID: '',
          ReleaseDate: ''
        },
        activeFilter: {
          q: '',
          LabelID: '',
          DeliveryStatusID: '',
          ReleaseDate: ''
        },
        statuses: [],
        fields: [
          {
            key: 'artistTitle',
            label: 'Attribution - Title',
            sortable: true,
          },
          {
            key: 'Label',
            label: 'Label',
            sortable: true,
          },
          {
            key: 'ReleaseDate',
            label: 'Release date',
            sortable: true,
          },
          {
            key: 'TotalTrackCount',
            label: 'Track #',
            headerTitle: 'Number of tracks',
            sortable: true,
          },
          {
            key: 'ProductDetails.DeliveryStatus.Description',
            label: 'Status',
            sortable: true,
          },
          {
            key: 'CoverArtAvailable',
            label: 'C',
            headerTitle: 'Cover available',
            sortable: true,
          },
          {
            key: 'ProductDetails.ReportingCode',
            label: 'Reporting Code',
            sortable: true,
          },
          {
            key: 'actions',
            label: 'Actions',
            class: 'link-icons',
          },
        ],
      }
    },
    methods: {
      setSearch () {
        this.page = 1
        this.activeFilter.q = this.filter.q 
        this.activeFilter.ReleaseDate = this.filter.ReleaseDate

        if (this.filter.LabelID != null)
          this.activeFilter.LabelID = this.filter.LabelID.LabelID
        else
          this.activeFilter.LabelID = ''

        if (this.filter.DeliveryStatusID != null)
          this.activeFilter.DeliveryStatusID = this.filter.DeliveryStatusID
        else 
          this.activeFilter.DeliveryStatusID = ''
      },
      resetSearch() {
        this.filter.q = ''
        this.filter.ReleaseDate = ''
        this.filter.DeliveryStatusID = ''
        
        this.setSearch()
      },
      deliveryStatusFormatter(data) {
        const ds = data.ProductDetails.DeliveryStatus
        let desc = this.statuses.find(s => {return s.code === ds.StatusID})
        if (desc) {
          desc = desc.label
        } else {
          desc = ds.Description
        }

        return desc 
      }
    },
    mounted () {
      this.$root.$data.crumbs = [
        {to: { name: 'release-search' }, text: 'Playlists'}
      ]

      // Set delivery status options
      for (let ds of this.$root.$data.fieldDefs.Album.AlbumDeliveryStatus.Options) {
        this.statuses.push({
          value: ds.JsonValue,
          label: ds.Value,
        })
      } 
    }
  }
</script>

<style scoped>
  label {
    font-weight: bold;
  }

  v-select {
    height: calc(1.5em + 0.75rem + 2px);
  }

  .inline {
    width: auto;
    display: inline-block;
  }
</style>